define("backalley/pods/components/basic-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M+V6TutD",
    "block": "{\"symbols\":[\"HotLoadFaIcon7x0ba19wsqt\",\"HotLoadFaIconcu8iuml8p6s\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasIcon\"]]],null,{\"statements\":[[0,\"  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[[22,\"icon\"]]]]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasQuestionMark\"]]],null,{\"statements\":[[0,\"  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"question-circle\"]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasTitle\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[22,\"titleClass\"]],[8],[0,\"\\n    \"],[1,[22,\"title\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"p\",true],[8],[14,3],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/basic-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});