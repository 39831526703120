define("backalley/pods/components/feed-sync/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SKtuUYlK",
    "block": "{\"symbols\":[\"HotLoadFaIcon36dqtn9qcqb\",\"HotLoadFaIconudn2qp2shyl\"],\"statements\":[[4,\"if\",[[23,0,[\"useAdvanced\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[[12,\"class\",\"button button-ternary button-xs\"]],[[\"@tagName\",\"@route\",\"@model\"],[\"button\",\"main.feeds.trigger\",[23,0,[\"integrationId\"]]]],{\"statements\":[[0,\"\\n    \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"rocket\"]]]],\"parameters\":[2]},null],[0,\"\\n    Goto Advanced Trigger\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"disabled\",[28,\"or\",[[23,0,[\"disabled\"]],[23,0,[\"isCoolingDown\"]]],null]],[12,\"class\",[29,[\"button button-ternary button-xs\\n      \",[28,\"if\",[[28,\"or\",[[23,0,[\"disabled\"]],[23,0,[\"isCoolingDown\"]]],null],\"button-disabled\"],null],\"\\n      \",[28,\"if\",[[23,0,[\"loading\"]],\"loading\"],null],\"\\n    \"]]],[3,\"on\",[\"click\",[23,0,[\"triggerJob\"]]]],[8],[0,\"\\n    \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"bolt\"]]]],\"parameters\":[1]},null],[0,\"\\n    \"],[1,[22,\"label\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/feed-sync/template.hbs"
    }
  });

  _exports.default = _default;
});