define("backalley/pods/main/curate/products/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ttXt9wxh",
    "block": "{\"symbols\":[\"HotLoadCurateProductVersionTwoForm6xx126yrstk\",\"HotLoadPageHeading557c976vt5w\",\"@model\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageHeading\",[23,0,[]],\"PageHeading\",\"PageHeading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageHeading\",\"PageHeading\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[],[[\"@fa\"],[\"store\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    Curate\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.curate.products\"]],{\"statements\":[[0,\"\\n      Products\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.curate.products.new\"]],{\"statements\":[[0,\"\\n      New\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"New\"],null],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"CurateProductVersionTwoForm\",[23,0,[]],\"CurateProductVersionTwoForm\",\"CurateProductVersionTwoForm\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"CurateProductVersionTwoForm\",\"CurateProductVersionTwoForm\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@product\",\"@isEdit\"],[[23,3,[]],false]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/curate/products/new/template.hbs"
    }
  });

  _exports.default = _default;
});