define("backalley/pods/components/boutique-product-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EQEdW0kt",
    "block": "{\"symbols\":[\"HotLoadFaIcon7icdsfznbmi\"],\"statements\":[[7,\"div\",true],[11,\"class\",[23,0,[\"styleNamespace\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[23,0,[\"classNames\",\"wrapper\"]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"activityReport\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[23,0,[\"classNames\",\"output\"]]],[8],[0,\"\\n        \"],[5,\"textarea\",[[12,\"readonly\",\"\"],[12,\"class\",\"markdown-source\"]],[[\"@spellcheck\",\"@value\"],[false,[23,0,[\"activityReport\"]]]]],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\",false],[12,\"class\",[29,[\"button button-ternary button-xs \",[28,\"if\",[[24,[\"loading\"]],\"loading\"],null]]]],[3,\"on\",[\"click\",[23,0,[\"checkActivity\"]]]],[8],[0,\"\\n      \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"search-plus\"]]]],\"parameters\":[1]},null],[0,\"\\n      Check Product Activity\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/boutique-product-activity/template.hbs"
    }
  });

  _exports.default = _default;
});